const theme = {
  margin: 30,
  transition: ".2s ease-in-out",
  shadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
  backgroundOpacity: "rgba(18, 16, 16, 0.5)",
  height: {
    header: {
      desktop: 90,
      mobile: 125,
    },
  },
  breakpoint: 800,
  radius: {
    normal: 5,
    big: 25,
  },
  color: {
    black: "#000",
    white: "#FFF",
    almostBlack: "#03152F",
    gray: "#131313",
    blue: "#3c35d3",
    cyan: "#07496d",
    lightDarkBlue: "#03152F",
    green: "#69b46f",
    lightGreen: "#0c8660",
    darkGreen: "#2C875E",
    cream: "#ffc99d",
    lightCream: "#FEF9F3",
    orange: "#F9AC62",
    primary: "#ffac69",
    pumpkin: "#cd5938",
    secondary: "#f6531e",
    darkBlue: "#01152e",
    dark: "#2b363a",
    lightPrimary: "#ffd6b5",
    semiWhite: "#fff4EB",
    alternativeWhite: "#fff0e3",
    alternativeBlack: "#2a363a",
    enterprise: {
      green: "#5DB473",
      lightOrange: "#FDE2C7",
    },
    workwise: {
      background: "#FEF0E3",
      orange: "#f47621",
      green: "#3e8359",
      yellow: "#e9af0f",
      purple: "#8e83ac",
      salmon: "#f5bc8c",
      red: "#F58277",
      blue: "#7BC1D1",
      next: "#04152f2e",
      darkBlue: "#04152F",
    },
  },
  typography: {
    small: 15,
    text: 16,
    default: 22,
    caption: 30,
    bookSize: 20,
    alternative: 24,
    myriad: "Myriad Pro",
    gothic: "CocoGothic",
    circular: "Circular",
    gascogne: "Gascogne",
    book: "Circular Book",
    circularBook: "Circular Book",
  },
  grid: {
    fullWidth: 1212,
  },
}

export default theme
