import { IOS_LINK, ANDROID_LINK } from './constant'


export const getDeviceLink = () => {
  let userAgent = ''
  let platform = ''

  if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent || navigator.vendor
    platform = navigator.platform || ''
  }

  if (typeof window !== 'undefined' && window.opera) {
    userAgent = window.opera
  }

  const isAppleDevice = /(Mac|iPhone|iPod|iPad)/i.test(userAgent) || platform.toLowerCase() === 'ios'

  return isAppleDevice ? IOS_LINK : ANDROID_LINK
}

export const openDeviceApp = () => {
  const link = getDeviceLink()

  window.open(link, '_blank')
}


export const scrollTop = () => {
  if (!document.body) {
    return null
  }

  if (document.body.scrollTo) {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  } else if (document.body.scrollTop) {
    document.body.scrollTop = 0
  }
}
