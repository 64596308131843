export const BREAKPOINT = {
  PHONE: 599,
  TABLET: 900,
  DESKTOP: 1200,
  WIDE_HEIGHT: 700,
  BIG_DESKTOP: 1800,
}

export const CONTACT_LINK = "contact@mind-app.io"
export const WORKWISE_CONTACT_LINK = "workwise@mind-app.io"
export const GIFT_LINK = "https://gift.mind-app.io/"
export const IOS_LINK =
  "https://itunes.apple.com/fr/app/mind-m%C3%A9ditations-guid%C3%A9es/id1073602315" // 'https://apps.apple.com/fr/app/mind-m%C3%A9ditations-guid%C3%A9es/id1073602315'
export const IOS_WORKWISE_LINK = "https://apps.apple.com/fr/app/workwise/id1539426628?l=fr"
export const ANDROID_WORKWISE_LINK = "https://play.google.com/store/apps/details?id=io.mindapp.workwise&hl=fr&gl=US";
export const ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.mylittleparis.meditation&hl=fr"
export const FACEBOOK_LINK = "https://www.facebook.com/mindappmeditation/"
export const INSTAGRAM_LINK = "https://www.instagram.com/mind_app/"
export const PRIVACY_LINK = "https://pages.mind-app.io/confidentialite"
export const CONDITIONS_LINK = "https://pages.mind-app.io/cgu"

export const  IMPROVEMENTS = [
  "Améliorer la concentration développer la créativité",
  "Réduire son stress pour favoriser le bien-être",
  "Cultiver son intelligence émotionnelle",
  "Améliorer la confiance en soi",
  "Favoriser la coopération par l’écoute et la communication",
  "Construire un environnent de bienveillance et d’empathie",
  "Cultiver la résilience dans un monde en transition",
  "S’engager au service d’un projet collectif qui a du sens",
]

export const CARDS_PROGRAM = [
  {
    color: "#F58277",
    caption: "module 1",
    title: "Entrainer son attention",
    text: "La pleine conscience est une qualité de présence attentive et bienveillante. Elle s'entraine par la méditation et ses bienfaits sont prouvés par la science.",
  },
  {
    color: "#70C2CF",
    caption: "module 2",
    title: "Cultiver le bien-être",
    text: "Nous héritons d'un esprit humain conçu pour se focaliser sur le négatif afin d'assurer sa survie. Comment orienter son attention pour développer une perception positive et confiante ?",
  },
  {
    color: "#F47621",
    caption: "module 3",
    title: "Réguler ses émotions",
    text: "Nos émotions sont des informations que nous avons rarement appris à décrypter. Comment comprendre et réguler nos états d'âme pour agir avec discernement ?",
  },
  {
    color: "#288257",
    caption: "module 4",
    title: "Développer la résilience",
    text: "Certaines situations nous mettent en grande difficulté et nous font réagir au quart de tour. Comment construire ses ressources intérieures pour répondre avec flexibilité et ouverture ?",
  },
  {
    color: "#F6AF00",
    caption: "module 5",
    title: "Construire son équilibre",
    text: "On peut parfois se perdre dans la frénésie du quotidien. Comment construire des bonnes habitudes qui permettent de prendre soin de ses ressources physiques, émotionnelles et mentales, au cœur de sa vie professionnelle ?",
  },
  {
    color: "#8276B3",
    caption: "module 6",
    title: "S'aligner avec ses intentions",
    text: "Nos schémas mentaux et émotionnels peuvent nous tirer dans tous les sens. Comment entrainer notre système de motivation afin d'aligner nos pensées, émotions, paroles et actions vers ce qui est essentiel pour nous ?",
  },
  {
    color: "#FFBB8D",
    caption: "module 7",
    title: "Nourrir la bienveillance dans ses relations",
    text: "Certaines de nos réactions automatiques minent nos ressources intérieures et nos talents. Comment changer la manière dont on se traite pour mieux écouter ses limites et le regard que l'on porte sur soi pour construire plus de confiance ?",
  },
  {
    color: "#2B363A",
    caption: "module 8",
    title: "S'engager dans une direction",
    text: "Après plusieurs semaines à explorer son fonctionnement pour construire équilibre intérieur et confiance, comment s'engager pour donner le meilleur de soi-même au service de tous ?",
  },
]


export const FOUNDERS = [
  {
    name: "Lori",
    lastname: "SCHWANBECK",
    bio: "Experte de la méditation en entreprise.<br />Design de programmes pour Google, Linkedin ou encore le gouvernement du Bhoutan…",
  },
  {
    name: "Mark",
    lastname: "COLEMAN",
    bio: "Fondateur du Mindfulness Training Institute.<br />Auteur de nombreux best sellers sur la méditation.",
  },
  {
    name: "Diane",
    lastname: "TAÏEB",
    bio: "Diplomée de l'ESSEC.<br />Formatrice, conférencière et instructrice en méditation de pleine conscience.<br /> 7 ans dans l'industrie de l'innovation et des startups.",
  },
  {
    name: "Julien",
    lastname: "Delon",
    bio: "Fondateur de l’application de méditation Mind.",
  },
]

export const INSTRUCTORS = [
  {
    name: "Diane",
    lastname: "TAÏEB",
    bio: "Diplomée de l'ESSEC.<br />Formatrice, conférencière et instructrice en méditation de pleine conscience.<br /> 7 ans dans l'industrie de l'innovation et des startups.",
    location: "paris.",
  },
  {
    name: "Elena",
    lastname: "ZAHARIEV",
    bio: "Instructrice de méditation et psychothérapeute corps-esprit. Diplômée de Sciences Po Paris et ESCP. 8 ans de management en entreprise.",
    location: "tours, paris.",
  },
  {
    name: "Dominique",
    lastname: "RETOUX",
    bio: "Instructeur de méditation.<br />6 ans au sein du Groupe SOS et 8 années chez MSF.",
    location: "paris.",
  },
  {
    name: "Inken",
    lastname: "DECHOW",
    bio: "Instructrice de méditation. Diplômée de l'University of Massachusetts Medical School 15 années chez L’ORÉAL",
    location: "paris."
  },
  {
    inline: true,
    name: "Coco",
    lastname: "Brac de la perrière",
    bio: "Coach de dirigeants,<br />conférencière sur la mindfulness et l'hyperconnexion, instructrice MBSR et teacher mindfulness en entreprise",
    location: "marseille, paris.",
  },
  {
    name: "Anne-Valérie",
    lastname: "Guillot-Giannoli",
    bio: "Instructrice de méditation D.U Médecine, Méditation, Neurosciences<br />Diplômée d'HEC Paris - 15 ans d'expérience de management en entreprise, multinationales et startup.",
    location: "bruxelles."
  },
  {
    name: "Soizic",
    lastname: "Michelot",
    bio: "Instructrice et autrice de méditation de pleine conscience.",
    location: "paris.",
  },
  {
    name: "Alice",
    lastname: "Duflot",
    bio: "Instructrice de méditation en entreprise.<br />Diplômée de l’école de Médecine de l’université de Massachusetts.<br />19 ans d’expérience en communication et Ressources Humaines en entreprise.",
    location: "Paris.",
  },
  {
    name: "Sandrine",
    lastname: "Jourden",
    bio: "Instructrice de pleine conscience et autocompassion.<br />Faculté de médecine de l'Université de Massachusetts. 16 ans dans des grands groupes et start-ups aux USA et en France.",
    location: "Lyon."
  },
  {
    name: "Alexis",
    lastname: "Desouches",
    bio: "Avocat de formation. Coach de dirigeants. Instructeur de méditation (MBSR & en entreprise) <br />Consultant international spécialisé dans la transformation des organisations <br />Co-président de Mindfulness Solidaire<br />Auteur de Booster sa confiance en soi - Marabout",
    location: "Paris - Bordeaux - Nantes",
  },
  {
    name: "Eugenio",
    lastname: "Correnti",
    bio: "Instructeur de méditation et yogathérapeute. <br />20 années dans des startup et grands groupes, en France et à l'international.",
    location: "Paris",
  },
  {
    name: "Damien",
    lastname: "Desnos",
    bio: "Instructeur de méditation (MBSR & MTI Brasil).<br />Diplômé de la faculté de médecine de San Diego (Californie)<br />Master en Neuroscience et Mindfulness <br />7 ans de Mindfulness en entreprises.",
    location: "Nantes & Grand Ouest",
  },
  {
    name: "laurence",
    lastname: "Debouvry",
    bio: "Docteur en médecine<br />Instructrice de méditation de pleine conscience<br />D.U.Médecine Méditation Neurosciences",
    location: "Lille",
  },
  {
    name: "Carine",
    lastname: "Gauthier",
    bio: "Coach et instructrice de méditation<br />15 ans de management en entreprise et accompagnement de dirigeants en France et à l'international.",
    location: "Paris",
  }
]


export const EXPERTS = [
  {
    name: "Thomas",
    lastname: "D’ANSEMBOURG",
    bio: "Psychothérapeute<br />Expert, formateur et conférencier en Communication Non Violente. Auteur du best-seller <i>Cessez d'être gentil, soyez vrai!</i>",
  },
  {
    name: "CLAIRE",
    lastname: "MIZZI",
    bio: "Psychologue et psychothérapeute.<br />Autrice et instructrice de méditation.",
  },
  {
    name: "JEAN-GÉRARD",
    lastname: "BLOCH",
    bio: "Professeur de médecine<br />Instructeur de méditation (intervient auprès des députés à l'Assemblée Nationale)<br />Fondateur du Diplôme Universitaire de Médecine, Méditation et Neurosciences à l'Université de Strasbourg.",
  },
  {
    name: "MARTIN",
    lastname: "AYLWARD",
    bio: "Formateur et auteur de méditation et Co-fondateur de l’application Mind et du Mindfulness Training Institute.",
  },
  // {
  //   name: "SATISH",
  //   lastname: "KUMAR",
  //   bio: "Penseur et activiste",
  // },
  // {
  //   name: "DOMINIQUE",
  //   lastname: "RETOUX",
  //   bio: "Instructeur de méditation",
  // },
  // {
  //   name: "ILIOS",
  //   lastname: "KOTSOU",
  //   bio: "Auteur spécialiste de l’intelligence émotionnelle",
  // },
  // {
  //   name: "INKEN",
  //   lastname: "DECHOW",
  //   bio: "Instructrice de méditation",
  // },
  // {
  //   name: "Delphine",
  //   lastname: "BATHO",
  //   bio: "Ancienne ministre et députée",
  // },
  // {
  //   name: "Thierry",
  //   lastname: "MARX",
  //   bio: "Chef étoilé",
  // },
  // {
  //   name: "GUILLAUME",
  //   lastname: "NERY",
  //   bio: "Champion du monde d’apnée",
  // },
  // {
  //   name: "PABLO",
  //   lastname: "SERVIGNE",
  //   bio: "Auteur et conférencier",
  // },
]
